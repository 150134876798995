<template>

  <div>
      <v-stepper v-model="stepper">
        <v-stepper-step
            editable
            step="1"
            :complete="stepper > 1"
        >
          Select Website: {{website}}
        </v-stepper-step>
        <v-stepper-content step="1">
          <v-card  :disabled="uploadInProgress">
            <v-card-text>
              <v-radio-group v-model="website">
                <v-radio
                    key="1"
                    label="readtheory.org"
                    value="readtheory"
                ></v-radio>
                <v-radio
                    key="2"
                    label="englishforeveryone.org"
                    value="e4e"
                ></v-radio>
              </v-radio-group>


            </v-card-text>
          </v-card>
        </v-stepper-content>
          <v-stepper-step
              editable
              step="2"
              :complete="stepper > 2"
          >
            Upload Ads.txt or paste ot below
          </v-stepper-step>
          <v-stepper-content step="2">
            <v-card  :disabled="uploadInProgress">
              <v-card-text>
                <v-file-input
                    v-model="files"
                    truncate-length="15"
                    placeholder="Upload your ads.txt file"
                    @change="loadAds"
                ></v-file-input>
                <v-btn :disabled="adsTxtContent === '' && uploadInProgress" color="secondary" @click="download">or load latest from website</v-btn>
                <v-textarea
                    v-model="adsTxtContent"
                    placeholder="or paste ads.txt content here:"
                    :disabled="uploadInProgress"
                    auto-grow
                ></v-textarea>
                <v-btn :disabled="adsTxtContent === '' && uploadInProgress" color="primary" @click="upload">Upload to website</v-btn>
              </v-card-text>
            </v-card>
          </v-stepper-content>


          <v-divider></v-divider>

          <v-stepper-step
              step="3"
              :editable="stepper > 1"
              :complete="uploadComplete"
          >
            Uploading...
          </v-stepper-step>
          <v-stepper-content step="3">
            <v-card>
              <v-card-text>Uploading to readtheory.org...</v-card-text>
              <v-progress-linear indeterminate v-if="uploadInProgress"></v-progress-linear>
            </v-card>
          </v-stepper-content>

      </v-stepper>
 </div>
</template>
<script>

import { Storage } from 'aws-amplify';

export default {
  name: 'AdsTxt',
  data() {
    return {
      files: [],
      adsTxtContent: '',
      stepper: 1,
      uploadInProgress: false,
      uploadComplete: false,
      website: 'readtheory'
    }
  },
  mounted() {
    window.AS = Storage;
  },
  watch: {
    website(newVal) {
      this.stepper=2;
      if (newVal === undefined || newVal === '') this.website = 'readtheory'
    }
  },
  methods: {
    reset() {
      this.files = [];
      this.adsTxtContent = '';
      this.stepper = 1;
      this.uploadInProgress = false;
      this.uploadComplete = false;
    },
    async loadAds(file) {
      if (file) {
        const fileReader = new FileReader();
        fileReader.onload = (fileLoadedEvent) => {
          let textFromFileLoaded = fileLoadedEvent.target.result;
          this.adsTxtContent = textFromFileLoaded
        };
        fileReader.readAsText(file, "UTF-8");
      }
    },
    async download() {
      const res = await Storage.get(`ads/${this.website}/ads.txt`, { download: true, cacheControl: 'no-cache' })
      this.adsTxtContent = await res.Body.text()
      this.$root.$emit('notify', {message: 'ads.txt loaded', type: 'success'})
    },
    async upload() {
      this.stepper = 3
      // console.log(this.usernames)
      this.uploadInProgress = true
        //upload the file
        try {
          await Storage.put(`ads/${this.website}/ads.txt`, this.adsTxtContent, {
            contentType: 'text/plain' // contentType is optional
          });
          this.$root.$emit('notify', {message: 'ads.txt updated', type: 'success'})
          this.reset()
          this.stepper = 1;
        } catch (err) {
          console.log('Error uploading file: ', err);
          this.$root.$emit('notify', {message: err.message, type: 'error'})
          this.stepper = 2
        }
        //
        finally {
          this.uploadInProgress = false
        }
    }
  }
}
</script>
