<template>
      <v-card>
        <AdConfigEditorDialog ref="editDialog" :on-delete="showDeleteDialog"></AdConfigEditorDialog>
        <v-card-title>
          Ad Configs
        </v-card-title>
        <v-progress-linear v-if="progress" indeterminate></v-progress-linear>
        <v-data-table
            :headers="headers"
            :items="items"
            :options.sync="options"
            :server-items-length="totalItems"
            :loading="loading"
            :search="options.search"
            class="elevation-1"
            @click:row="openItem"
        >
          <template v-slot:item.actions="{ item }">
            <v-icon
              class="mr-2"
              @click.stop.prevent="impersonate(item)">
              mdi-login
            </v-icon>
            <v-icon
                class="mr-2"
                @click.stop.prevent="openItem(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon v-if="isCognitoAdmin"
                color.hover="red"
                @click.stop.prevent="showDeleteDialog(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:item.roles="{ item }">
            <v-chip v-for="role in item.roles" :key="role"
                    close-icon="mdi-close-outline"
                    :color="getRoleColor(role)"
                    label
                    x-small
            >{{getRoleName(role)}}</v-chip>
          </template>
        </v-data-table>

      </v-card>
    </template>
<script>
import _ from 'lodash'
// import EditorDialog from "@/components/UserEditorDialog";
// import {ROLE_ADMIN, ROLE_STUDENT, ROLE_TEACHER, ROLES} from "@/constants";
// import DeleteDialog from "@/components/DeleteDialog";
// import {EventBus, USER_UPDATED_EVENT} from "@/eventbus";
import {mapState} from "vuex";
import AdConfigEditorDialog from "@/components/AdConfigEditorDialog";

export default {

  name: 'AdConfigTable',
  components: {AdConfigEditorDialog},
  // components: {DeleteDialog, EditorDialog},

  data: () => {
    return {
      progress: false,
      dialog: false,
      dialogDelete: false,
      lastFetchQuery: '',
      headers: [
        {text: 'id', value: 'id'},
        {text: 'Name', value: 'name'},
        {text: 'Created At', value: 'create_date'},
        {text: 'Updated At', value: 'update_date'},
        {text: 'Config', value: 'config'},
        // {text: 'Actions', value: 'actions', sortable: false },
      ],
      items: [],
      options: {
        search: '',
        sortBy: ['id'],
        sortDesc: [false]
      },
      totalItems: 0,
      loading: false,
    }
  },
  computed: {
    ...mapState(['groups']),
    isCognitoAdmin() {
      return this.groups.indexOf('admin') > -1
    },
  },
  watch: {
    options: {
      handler() {
        this.debouncedGetDataFromApi()
      },
      deep: true,
    },
  },
  created() {},
  methods: {
    editItem() {

    },

    showDeleteDialog(item) {
      this.$refs.deleteDialog.show(item.username, item);
    },

    async deleteCurrentItem(item) {
      this.progress = true;
      this.progress = true;
      let url = `/user/delete?id=${item.id}`;
      const {data, status} = await this.axios(url);
      if (status !== 200) {
        this.progress = false;
        this.$root.$emit('notify', {message: `Server returned with status code: ${status}`, type: 'error'})
        return;
      }
      if (data.success === true || data.success === 'true') {
        this.progress = false;
        this.$root.$emit('notify', {message: `User ${item.username} deleted.`, type: 'success'})
        this.users = this.users.filter(user => user.id !== item.id)
      } else {
        this.progress = false;
        this.$root.$emit('notify', {message: `Server: ${data.message}`, type: 'error'})
      }
    },


    openItem(item) {
      this.$refs.editDialog.show(item);
    },

    async getDataFromApi() {
      const {sortBy, sortDesc, page, itemsPerPage} = this.options
      let url = `/ad_config/list?limit=${itemsPerPage}&offset=${(page - 1) * itemsPerPage}&sort=${sortBy}&desc=${sortDesc}`;
      if (url !== this.lastFetchQuery) {
        this.loading = true
        try {
          const {data, status} = await this.axios(url);
          if (status !== 200) {
            this.loading = false;
            this.$root.$emit('notify', {message: `Server returned with status code: ${status}`, type: 'error'})
          }
          this.items = data.items.map(item => ({...item, config: JSON.parse(item.config)}));
          if (data.total != this.totalItems) {
            this.options.page = 1;
          }
          this.totalItems = data.total;

        }
        catch (e) {
          console.error(e);
        }
        finally {
          this.loading = false
        }
      }
    },
    debouncedGetDataFromApi: _.debounce(function () {
      this.getDataFromApi()
    }, 1000, {leading: true})

  }

};
</script>
