<template>
  <v-row>
    <v-dialog
        v-model="dialog"
        hide-overlay
        fullscreen
        transition="dialog-bottom-transition"
    >
      <div>
        <v-card elevation="2">
          <v-toolbar
              dark
              color="primary"
          >
            <v-btn
                icon
                dark
                @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Edit Ad Config <b>{{ item.name }}</b></v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn
                  dark
                  text
                  @click="save"
              >
                Save
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-subheader>Ad Configuration
          </v-subheader>
          <v-form class="pa-5">
            <v-row class="left">
              <v-col cols="1"
                     sm="1"
                     md="1">
                <v-text-field
                    v-model="item.id"
                    label="ID"
                    disabled
                ></v-text-field>
              </v-col>
              <v-col cols="9"
                     sm="9">
                <v-text-field
                    v-model="item.name"
                    label="Name"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-jsoneditor v-if="item.config" v-model="item.config" :options="json_editor_options" :plus="true" height="80vh"/>

<!--            <AdPage v-for="(page, $idx) in item.config.pages" :page="page" :key="$idx"></AdPage>-->
<!--              <JsonEditor-->
<!--                  :options="{-->
<!--            confirmText: 'confirm',-->
<!--            cancelText: 'cancel',-->
<!--        }"-->
<!--                  :objData="item.config"-->
<!--                  v-model="item.config" >-->
<!--              </JsonEditor>-->
          </v-form>
        </v-card>
      </div>
    </v-dialog>
  </v-row>
</template>
<script>
import _ from 'lodash';

import {EventBus, NOTIFY_EVENT} from "@/eventbus";
import {mapState} from "vuex";
// import AdPage from "@/components/Ads/AdPage";
import VJsoneditor from 'v-jsoneditor'


export default {
  name: 'AdConfigEditorDialog',
  props: {
    onDelete: {
      type: Function,
      default: () => {
      },
    },
    onClose: {
      type: Function,
      default: () => {
      },
    }
  },
  data: () => ({
    json_editor_options: { mode: 'code' },
    dialog: false,
    item: {},
  }),
  watch: {
    dialog: function(newVal) {
      if (!newVal) this.onClose()
    }
  },
  components: {
    // AdPage,
    VJsoneditor,

  },
  computed: {
    ...mapState(['user','groups']),
    isCognitoAdmin() {
      return this.groups.indexOf('admin') > -1
    }
  },
  methods: {
    onError() {
      this.notify('JSON Error', 'error')
    },
    notify(message, type) {
      EventBus.$emit(NOTIFY_EVENT, {message, type})
    },
    async show(item) {
      this.item = null;
      this.dialog = true;
      this.originalItem = item;
      this.item = _.cloneDeep(item);
      // let pages = {};
      // _.forEach(this.item.config.pages, page => {
      //   console.log(page.name)
      //   pages[page.name] = page
      // })
      // this.item.config.pages = pages
    },
    reset() {
      this.item = _.cloneDeep(this.originalItem);
    },
    handleDelete() {
      this.dialog = false;
      this.onDelete(this.item)
    },

    async save() {
      let url = '/ad_config/' + this.item.id
      const omitFields = ['create_date', 'update_date']
      this.item.modified_by = this.user?.username;
      const payload = _.omit(this.item, omitFields)

      payload.config = JSON.stringify(this.item.config);
      const {data, status} = await this.axios.post(url, payload)
      if (status !== 200) {
        this.notify(`Error: server returned status: ${status}`, 'error')
      }
      if (data.success === true) {
        this.dialog = false;
        this.originalItem = this.item;
        // EventBus.$emit(AD_CONFIG_UPDATED_EVENT, this.originalItem);
        this.$root.$emit('notify', {'message': `Changes saved for ${this.item.name}.`, type: 'success'})
      } else {
        this.notify(data.message, 'error')
      }

    }
  },
  created() {
    EventBus.$on(NOTIFY_EVENT, ({message, type}) => {
      this.notify(message, type)
    })
  },
  destroyed() {
    EventBus.$off(NOTIFY_EVENT)
  }

}
</script>
