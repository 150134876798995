<template>

  <div>
      <v-stepper v-model="stepper">
        <v-stepper-step
              editable
              step="1"
              :complete="stepper > 1"
          >
            Upload new version of asc.readtheory
          </v-stepper-step>
          <v-stepper-content step="1">
            <v-card  :disabled="uploadInProgress">
              <v-card-text>
                Non-Coppa wrapper:
                <v-breadcrumbs>
                  <v-breadcrumbs-item href="https://readtheory.org/static/assets/app/asc.readtheory.js">https://readtheory.org/static/assets/app/asc.readtheory.js</v-breadcrumbs-item>
                </v-breadcrumbs>
                <v-file-input
                    v-model="regular_file"
                    truncate-length="15"
                    placeholder="Upload regular js file to replace it"
                ></v-file-input>
                Coppa wrapper:
                <v-breadcrumbs>
                  <v-breadcrumbs-item href="https://readtheory.org/static/assets/app/asc.readtheory.js">https://readtheory.org/static/assets/app/asc.readtheory.coppa.js</v-breadcrumbs-item>
                </v-breadcrumbs>
                <v-file-input
                    v-model="coppa_file"
                    truncate-length="15"
                    placeholder="Upload coppa js file to replace it"
                ></v-file-input>
                <v-btn :disabled="coppa_file === null && regular_file === null && uploadInProgress" color="primary" @click="upload">Upload new file/s to website</v-btn>
              </v-card-text>
            </v-card>
          </v-stepper-content>


          <v-divider></v-divider>

          <v-stepper-step
              step="2"
              :editable="stepper > 1"
              :complete="uploadComplete"
          >
            Uploading...
          </v-stepper-step>
          <v-stepper-content step="3">
            <v-card>
              <v-card-text>Uploading to readtheory.org...</v-card-text>
              <v-progress-linear indeterminate v-if="uploadInProgress"></v-progress-linear>
            </v-card>
          </v-stepper-content>

      </v-stepper>
 </div>
</template>
<script>

import { Storage } from 'aws-amplify';

export default {
  name: 'AscDeployVersion',
  data() {
    return {
      regular_file: null,
      coppa_file: null,
      stepper: 1,
      uploadInProgress: false,
      uploadComplete: false,
      website: 'readtheory'
    }
  },
  mounted() {
    this.$root.$on('asc-deploy-version', () => {
      this.stepper = 1;
    })
  },
  methods: {
    reset() {
      this.regular_file = null;
      this.coppa_file = null;
      this.stepper = 1;
      this.uploadInProgress = false;
      this.uploadComplete = false;
    },
    async upload() {
      this.stepper = 2
      // console.log(this.usernames)
      this.uploadInProgress = true
        //upload the file
        try {
          if (this.regular_file) {
            await Storage.put('asc/asc.readtheory.js', this.regular_file, {
              level: 'public',
              contentType: 'text/javascript'
            })
            this.$root.$emit('notify', {message: `asc.readtheory.js updated`, type: 'success'})
          }
          if (this.coppa_file) {
            await Storage.put('asc/asc.readtheory.coppa.js', this.coppa_file, {
              level: 'public',
              contentType: 'text/javascript'
            })
            this.$root.$emit('notify', {message: `asc.readtheory.coppa.js updated`, type: 'success'})

          }
          this.reset()
          this.stepper = 1;
        } catch (err) {
          console.log('Error uploading file: ', err);
          this.$root.$emit('notify', {message: err.message, type: 'error'})
          this.stepper = 2
        }
        //
        finally {
          this.uploadInProgress = false
        }
    }
  }
}
</script>
